export const regular_xs = 'font-mimopro font-normal text-xs';
export const regular_sm = 'font-mimopro font-normal text-sm';
export const regular_base = 'font-mimopro font-normal text-base';
export const regular_lg = 'font-mimopro font-normal text-lg';

export const medium_xs = 'font-mimopro font-medium text-xs';
export const medium_sm = 'font-mimopro font-medium text-sm';
export const medium_base = 'font-mimopro font-medium text-base';
export const medium_lg = 'font-mimopro font-medium text-lg';
export const medium_xl = 'font-mimopro font-medium text-xl';
export const medium_2xl = 'font-mimopro font-medium text-2xl';
export const medium_3xl = 'font-mimopro font-medium text-3xl';

export const bold_xs = 'font-mimopro font-bold text-xs';
export const bold_sm = 'font-mimopro font-bold text-sm';
export const bold_base = 'font-mimopro font-bold text-base';
export const bold_lg = 'font-mimopro font-bold text-lg';
