import * as React from 'react';

function FacebookIconComponent(props: any) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 1.50342C6.7019 1.50342 2 6.20532 2 12.0034C2 17.308 5.93645 21.6813 11.0447 22.3911V14.9676H8.58245V12.1147H11.0447V10.0116C11.0447 7.57032 12.5346 6.24102 14.7123 6.24102C15.7571 6.24102 16.6527 6.31872 16.9131 6.35232V8.90382L15.4032 8.90487C14.2189 8.90487 13.991 9.46767 13.991 10.293V12.1137H16.8155L16.4459 14.9665H13.9899V22.3858C19.0814 21.6603 23 17.2954 23 12.0034C23 6.20532 18.2981 1.50342 12.5 1.50342Z"
        fill="#4267B2"
      />
    </svg>
  );
}

export default FacebookIconComponent;
