import * as React from 'react';

function AppleIconComponent(props: any) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.4933 17.5895C19.1908 18.2883 18.8328 18.9314 18.418 19.5228C17.8526 20.3289 17.3896 20.8869 17.0329 21.1968C16.4798 21.7054 15.8872 21.9659 15.2527 21.9807C14.7971 21.9807 14.2478 21.8511 13.6083 21.5882C12.9667 21.3264 12.3771 21.1968 11.838 21.1968C11.2726 21.1968 10.6662 21.3264 10.0175 21.5882C9.36793 21.8511 8.84462 21.9881 8.44451 22.0017C7.83601 22.0276 7.22949 21.7598 6.62407 21.1968C6.23767 20.8598 5.75435 20.282 5.17536 19.4635C4.55415 18.5895 4.04342 17.5759 3.64331 16.4204C3.21481 15.1723 3 13.9637 3 12.7936C3 11.4533 3.28962 10.2973 3.86972 9.32852C4.32563 8.55039 4.93216 7.93658 5.69127 7.48598C6.45038 7.03537 7.2706 6.80575 8.1539 6.79106C8.63722 6.79106 9.27102 6.94056 10.0587 7.23438C10.8441 7.52918 11.3484 7.67869 11.5695 7.67869C11.7348 7.67869 12.295 7.50388 13.2447 7.15537C14.1428 6.83217 14.9008 6.69834 15.5218 6.75106C17.2045 6.88686 18.4686 7.55017 19.3093 8.7452C17.8044 9.65702 17.06 10.9341 17.0748 12.5725C17.0884 13.8486 17.5514 14.9106 18.4612 15.7538C18.8735 16.1451 19.334 16.4476 19.8463 16.6624C19.7352 16.9846 19.618 17.2932 19.4933 17.5895V17.5895ZM15.6341 2.40353C15.6341 3.40376 15.2687 4.33767 14.5404 5.20209C13.6614 6.22972 12.5982 6.82353 11.4453 6.72983C11.4306 6.60983 11.4221 6.48354 11.4221 6.35082C11.4221 5.39061 11.8401 4.36298 12.5824 3.52276C12.953 3.09734 13.4243 2.74361 13.9959 2.46143C14.5663 2.18346 15.1058 2.02974 15.6132 2.00342C15.628 2.13713 15.6341 2.27086 15.6341 2.40352V2.40353Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default AppleIconComponent;
