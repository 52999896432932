import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';
import { useMachine } from '@xstate/react';
import InputFieldComponent from '@components/web-landingpage/InputField';
import loginStateMachine from '../../../machines/loginStateMachine';
import GoogleIconComponent from '@components/icons/googleIcon';
import AppleIconComponent from '@components/icons/appleIcon';
import FacebookIconComponent from '@components/icons/facebookIcon';
import { ErrorToastComponent } from '../ErrorToast';
import {
  BodyTextSmall,
  ButtonText,
  ButtonSmallText,
} from '@components/web-landingpage/Texts';
import Link from 'next/link';
import { IconButtonComponent } from '@components/buttons/IconButton';
import { getRegisterPageLink } from '@utils/link-utils-web';
import PrimaryButtonComponent from '@components/buttons/PrimaryButton';
import { medium_xs } from '../texts/Styles';
import { LoginOrSignupLocation } from '@customtypes/events';

function LoginFormComponent({
  trackLoginOrSignupLocation,
}: {
  trackLoginOrSignupLocation: LoginOrSignupLocation;
}) {
  const [loginState, sendLoginStateEvent] = useMachine(loginStateMachine, {
    input: {
      trackLoginOrSignupLocation,
    },
  });
  const { email, password } = loginState.context.state;

  const { email: emailError, password: passwordError } =
    loginState.context.state.errors;

  const router = useRouter();

  const passwordInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const { email } = router.query;

    if (email) {
      sendLoginStateEvent({ type: 'ENTER_EMAIL', email: `${email}` });
      passwordInputRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (loginState.matches('serviceError')) {
      toast.dismiss();
      toast.custom((toast) => (
        <ErrorToastComponent toast={toast}>
          {loginState.context.state.errors.serviceError}
        </ErrorToastComponent>
      ));
    }
  }, [loginState.context.state.errors]);

  return (
    <div className="space-y-8 tablet:space-y-6">
      <div className="space-y-4">
        <div className="text-text-primary-dark">
          <div className={`${medium_xs} mb-2 text-start`}>Email</div>
          <InputFieldComponent
            id="email"
            placeholder="Your e-mail"
            name="email"
            type="email"
            required={true}
            value={email}
            onChange={(e) => {
              sendLoginStateEvent({
                type: 'ENTER_EMAIL',
                email: e.target.value,
              });
            }}
            autoComplete="on"
            errorMessage={emailError}
            onEnter={() => {
              sendLoginStateEvent({
                type: 'SUBMIT_FORM',
                data: {
                  email,
                  password,
                },
              });
            }}
          />
          <div className={`${medium_xs} mb-2 text-start`}>Password</div>
          <InputFieldComponent
            id="password"
            placeholder="Your password"
            name="password"
            type="password"
            required={true}
            value={password}
            onChange={(e) => {
              sendLoginStateEvent({
                type: 'ENTER_PASSWORD',
                password: e.target.value,
              });
            }}
            autoComplete="on"
            errorMessage={passwordError}
            ref={passwordInputRef}
            onEnter={() => {
              sendLoginStateEvent({
                type: 'SUBMIT_FORM',
                data: {
                  email,
                  password,
                },
              });
            }}
          />
          <PrimaryButtonComponent
            className="w-full"
            onClick={() => {
              sendLoginStateEvent({
                type: 'SUBMIT_FORM',
                data: {
                  email,
                  password,
                },
              });
            }}
          >
            <ButtonText>
              {loginState.matches('loading') ? 'Logging you in ...' : 'Log in'}
            </ButtonText>
          </PrimaryButtonComponent>
        </div>
        <div className="grid grid-cols-3 gap-4 tablet:h-full">
          <IconButtonComponent
            className="h-12"
            title="Google"
            onClick={() => {
              sendLoginStateEvent({
                type: 'LOGIN_WITH_GOOGLE',
              });
            }}
          >
            <GoogleIconComponent className="h-6 w-6 flex-shrink-0" />
          </IconButtonComponent>
          <IconButtonComponent
            className="h-12"
            title="Apple"
            onClick={() => {
              sendLoginStateEvent({
                type: 'LOGIN_WITH_APPLE',
              });
            }}
          >
            <AppleIconComponent className="h-6 w-6 flex-shrink-0 text-black" />
          </IconButtonComponent>
          <IconButtonComponent
            className="h-12"
            title="Facebook"
            onClick={() => {
              sendLoginStateEvent({
                type: 'LOGIN_WITH_FACEBOOK',
              });
            }}
          >
            <FacebookIconComponent className="h-6 w-6 flex-shrink-0" />
          </IconButtonComponent>
        </div>
      </div>
    </div>
  );
}

export default LoginFormComponent;
