import 'firebase/auth';
import { withUser, AuthAction } from 'next-firebase-auth';
import { Toaster } from 'react-hot-toast';
import LoginFormComponent from '@components/web/signup-signin/LoginForm';
import Link from 'next/link';
import {
  BodyTextSmall,
  ButtonSmallText,
  Heading1,
} from '@components/web-landingpage/Texts';
import MascotSittingIconComponent from '@components/illustrations/MascotSitting';
import MimoWebLogo from '@components/icons/mimoWebLogo';
import Head from 'next/head';
import { LoginPageConfetti } from '@components/web-landingpage/ConfettiBackground';
import { GetStaticProps } from 'next';
import Script from 'next/script';
import {
  getPasswordResetPageLink,
  getRegisterPageLink,
} from '@utils/link-utils-web';
import { useRouter } from 'next/router';

const WebLoginPage = () => {
  const router = useRouter();

  return (
    <>
      <Script
        id="cookieyes"
        type="text/javascript"
        src="https://cdn-cookieyes.com/client_data/3ac4d695a7fb984394170f54/script.js"
        strategy="beforeInteractive"
      />
      <Head>
        <title>
          Log in to Mimo to keep learning to code. Access your account
        </title>
        <meta
          name="description"
          content="Start learning with Mimo today and future-proof your skills for tomorrow. Join our community of 25+ million learners. Learn HTML, CSS, JavaScript, Python, and more"
          key="description"
        ></meta>
        <meta
          name="og:title"
          content="Log in to Mimo to keep learning to code. Access your account"
          key="og:title"
        ></meta>
        <meta
          name="og:description"
          content="Start learning with Mimo today and future-proof your skills for tomorrow. Join our community of 25+ million learners. Learn HTML, CSS, JavaScript, Python, and more"
          key="og:description"
        ></meta>
      </Head>
      <Toaster
        toastOptions={{
          duration: Infinity,
        }}
        containerStyle={{ zIndex: 50 }}
        position="top-center"
        reverseOrder={false}
      />
      <div className="relative z-30 bg-marketing-background-primary text-marketing-text-primary">
        <div className="m-auto flex min-h-screen flex-col justify-start space-y-14 px-6 pt-16 tablet:max-w-[440px] tablet:justify-center tablet:px-0 tablet:py-0">
          <Link href="/" className="flex justify-center align-bottom">
            <MimoWebLogo className="h-8"></MimoWebLogo>
          </Link>
          <div className="flex flex-col justify-start space-y-14 text-center tablet:max-h-[500px] tablet:min-h-[500px]">
            <Heading1 className="text-[32px] font-bold leading-[40px] tablet:text-[40px] tablet:leading-[48px]">
              Welcome back!
            </Heading1>
            <LoginFormComponent trackLoginOrSignupLocation="web_login_or_signup_page"></LoginFormComponent>
            <div className="flex flex-col items-center justify-between space-y-4 text-marketing-text-primary">
              <div className="mobile-fallback__page-content space-x-2">
                <BodyTextSmall className="text-center">
                  Don&apos;t have an account?
                </BodyTextSmall>
                <Link
                  href={{
                    pathname: getRegisterPageLink(),
                    query: router.query,
                  }}
                  className="font-bold underline hover:text-product2-content-accentLight"
                >
                  Create an account
                </Link>
              </div>
              <Link
                href={getPasswordResetPageLink()}
                className="hover:text-product2-content-accentLight"
              >
                <ButtonSmallText className="underline">
                  Reset your password
                </ButtonSmallText>
              </Link>
            </div>
          </div>
        </div>
        <MascotSittingIconComponent className="absolute bottom-0 right-14 z-[-1] hidden h-36 tablet:block"></MascotSittingIconComponent>
        <LoginPageConfetti />
      </div>
    </>
  );
};

export default withUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})(WebLoginPage);

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  };
};
