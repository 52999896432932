import LoadingIconComponent from '@components/icons/loadingIcon';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const PrimaryButtonComponent = React.forwardRef(
  (
    {
      className,
      onClick,
      children,
      disabled,
      isLoading,
      ...props
    }: {
      className?: string;
      onClick?: () => void;
      children: React.ReactNode;
      disabled?: boolean;
      isLoading?: boolean;
      props?: any;
    },
    forwardedRef: any,
  ) => {
    return (
      <button
        type="submit"
        ref={forwardedRef}
        onClick={disabled || isLoading ? undefined : onClick}
        className={twMerge(
          `flex items-center justify-center rounded-xl ${
            disabled
              ? 'cursor-not-allowed bg-product-button-primary-disabled-dark text-product-button-primary-disabled-dark'
              : 'bg-product-button-primary-enabled-dark hover:bg-product-button-primary-hovered-dark focus:outline-none'
          } px-6 py-3 text-base font-semibold shadow-sm`,
          className,
        )}
        {...props}
      >
        {isLoading ? (
          <LoadingIconComponent className="h-[50%] animate-spin text-white"></LoadingIconComponent>
        ) : (
          children
        )}
      </button>
    );
  },
);

export default PrimaryButtonComponent;
